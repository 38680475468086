import Fuse from 'fuse.js'
import '@fontsource/work-sans'

import DATA_ARRAY from './data'
import { Entry, EntryKeys, Links } from './types'

// const TYPES = []

const KEYS_TO_INDEX: EntryKeys[] = ['title', 'tags', 'description']

const fuse = new Fuse(DATA_ARRAY, {
  includeMatches: false,
  threshold: 0.6,
  useExtendedSearch: true,
  keys: KEYS_TO_INDEX,
})

const initialValue = DATA_ARRAY.map((item) => ({
  item: item,
  matches: [],
  score: 1,
}))

function renderLinks(links: Links) {
  const socialMarkup = (key: string) => `<li class="icon">
    <a href="${links[key]}" data-icon="${key}" title="${key}" target="_blank" rel="noreferrer"></a>
  </li>`

  let socialEls = ''

  if (links?.github) {
    socialEls += socialMarkup('github')
  }
  if (links?.website) {
    socialEls += socialMarkup('website')
  }
  if (links?.demo) {
    socialEls += socialMarkup('demo')
  }

  return socialEls
}

function getBaseLink(links: Links) {
  if (links.github) {
    return links.github
  } else if (links.website) {
    return links.website
  } else if (links.demo) {
    return links.demo
  } else {
    return null
  }
}

function wrapWithLink(link, element) {
  return `<a href="${link}" target="_blank">${element}</a>`
}

function scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

window.addEventListener('DOMContentLoaded', () => {
  const searchInputEl: HTMLInputElement = document.querySelector('#search-input')
  const resultsContainerEl: HTMLElement = document.querySelector('#results-container')
  const scrollToTopEl: HTMLButtonElement = document.querySelector('#scroll-to-top')

  function handleSearch(searchString: string) {
    const results = searchString ? fuse.search(searchString) : initialValue
    searchInputEl.parentElement.dataset.count = `${results.length}`

    resultsContainerEl.innerHTML = results
      .map(({ item }: { item: Entry }) => {
        const baseLinkPath = getBaseLink(item.links)

        const resultItem = `<li class="result-item">
          <h2 class="title">${wrapWithLink(
            baseLinkPath,
            `${item.title}${item.img ? `<span><img src="${item.img}"/></span>` : ''}`
          )}</h2>
          ${item?.description ? `<p class="description">${item.description}</p>` : ``}
          ${
            item?.tags && item?.tags.length > 0
              ? `<ul class="pill--container">${item.tags
                  .map((tag) => `<li class="pill">${tag}</li>`)
                  .join('')}</ul>`
              : ``
          }
          ${
            item.links
              ? `<ul class="socials--container">${renderLinks(item.links)}</ul>`
              : ``
          }
        </li>`

        return resultItem
      })
      .join('')
  }

  if (searchInputEl) {
    searchInputEl.addEventListener('input', (e) => {
      handleSearch((e.target as HTMLInputElement).value)
    })
  }
  if (scrollToTopEl) {
    scrollToTopEl.addEventListener('click', () => {
      scrollToTop()
    })
  }

  handleSearch('')
})
