import { Entry } from '../types'

const WEBSITES: Entry[] = [
  {
    id: 'dbdiagram.io',
    title: 'dbdiagram.io',
    description:
      'A free, simple tool to draw ER diagrams by just writing code. Designed for developers and data analysts.',
    links: {
      website: 'https://dbdiagram.io/',
    },
    tags: ['database', 'design', 'uml'],
  },
  {
    id: 'svgtocss',
    title: 'svg-to-css',
    description: 'Convert SVG icons to CSS base64 strings.',
    links: {
      website: 'https://dvlp.haus/svg-to-css/',
    },
    tags: ['svg', 'icons', 'css'],
  },
  {
    id: 'keycodeinfo',
    title: 'keycode.info',
    description: 'Debug JavaScript event keycodes.',
    links: {
      website: 'https://keycode.info/',
    },
    tags: ['javascript', 'keycode', 'events'],
  },
  {
    id: 'fontsource',
    title: '@fontsource.io',
    description: 'Self-host Open Source fonts in neatly bundled NPM packages.',
    links: {
      website: 'https://fontsource.org/fonts',
    },
    tags: ['npm', 'fonts'],
  },
  {
    id: 'bundlephobia',
    title: 'bundle phobia.com',
    description: 'find the cost of adding a npm package to your bundle',
    links: {
      website: 'https://bundlephobia.com/',
    },
    tags: ['npm', 'bundles'],
  },
  {
    id: 'caniuse',
    title: 'caniuse',
    description:
      'Check support of front-end web technologies on desktop and mobile web browsers.',
    links: {
      website: 'https://caniuse.com/',
    },
    tags: ['web', 'support'],
  },
  {
    id: 'tailwindshades',
    title: 'Tailwind Shades',
    description: 'A tool to help generate color shades for tailwindcss.',
    links: {
      website: 'https://www.tailwindshades.com/',
    },
    tags: ['tailwindcss', 'color', 'config'],
  },
  {
    id: 'undraw',
    title: 'unDraw',
    description:
      'Browse to find the images that fit your needs and click to download. Use the on-the-fly color image generation to match your brand identity.',
    links: {
      website: 'https://undraw.co/illustrations',
    },
    tags: ['illustrations', 'design'],
  },
].map((item) => ({
  ...item,
  type: 'website',
}))

export default WEBSITES
